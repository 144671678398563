<template>
  <component
    :is="currentComponent"
    :data="data"
  />
</template>

<script>
import { findHall } from '@/api/hall'
export default {
  name: 'ShowHallInfo',
  components: {
    HallInUseShow: () => import('@/views/halls/show/pc/in_use/index'),
    HallIdleShow: () => import('@/views/halls/show/pc/Idle'),
    HallPauseShow: () => import('@/views/halls/show/pc/Pause')
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    },

    currentComponent() {
      switch (this.data.status_slug) {
        case 'in_use':
          return 'HallInUseShow'
        case 'idle':
          return 'HallIdleShow'
        case 'pause':
          return 'HallPauseShow'
        default:
          return ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findHall(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    }
  }
}
</script>
