<template>
  <div id="box">
    <pc v-if="screenWidth > 1366" />
    <mobile v-else />
  </div>
</template>

<script>
import elementResizeDetector from 'element-resize-detector'
import Pc from '@/views/halls/show/pc/index'
import Mobile from '@/views/halls/show/mobile/index'
export default {
  name: 'HallShow',
  components: {
    Pc,
    Mobile
  },
  data() {
    return {
      screenWidth: '',
      screenHeight: ''
    }
  },
  mounted() {
    const that = this
    const erd = elementResizeDetector()
    erd.listenTo(document.getElementById('box'), (element) => {
      that.screenWidth = element.offsetWidth
      that.screenHeight = element.offsetHeight
    })
  }
}
</script>

<style lang="less" scoped>
#box {
  height: 100%;
}
</style>
